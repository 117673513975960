import type { FepFunctions, IAddEventHandlersOptions, IConfig, IGigyaEvents } from "@fep/interfaces";
import * as gigyaFunctions from "@fep/gigyaFunction";
import * as functions from "@fep/functions";
import { getSsoGigyaBaseUrl } from "@fep/gigyaFunction/getSsoGigyaBaseUrl";
import { GoogleAnalytics, loadScript, injectFonts, FepError, FepLoggerMixin, applyMixins } from "@fep/services";
import { version } from "../package.json";
import { parse } from "json5";
import type { ISapAccount } from "@msd-cex/sap-cdc-shared";

/* eslint-disable-next-line @typescript-eslint/no-unsafe-declaration-merging */
export class Fep {
  private _config?: IConfig;
  private _initialized = false;
  private _googleAnalytics?: GoogleAnalytics;
  private _events?: IGigyaEvents;
  private _functions?: FepFunctions;

  constructor() {
    this._handleBFCacheRestore();

    const config = this._fetchAndParseConfig();

    if (config) {
      this.setConfig(config);
    }
  }

  get version(): string {
    return version;
  }

  get lang(): string {
    return this._config?.lang ?? "en";
  }

  get market(): string {
    return this._config?.market ?? "";
  }

  get portal(): string {
    return this._config?.portal ?? "";
  }

  get apiKey(): string {
    return this._config?.apiKey ?? "";
  }

  get ga(): GoogleAnalytics {
    if (this._googleAnalytics) {
      return this._googleAnalytics;
    }

    if (!this._config || typeof this._config.gaEnabled !== "boolean") {
      throw new FepError(
        "Invalid configuration: 'gaEnabled' must be a boolean. Ensure 'loadGigya' method was called to initialize configuration."
      );
    }

    this._googleAnalytics = new GoogleAnalytics(this._config.gaEnabled);

    return this._googleAnalytics;
  }

  get ssoEnabled(): boolean {
    return !!this._config?.ssoEnabled;
  }

  get functions(): FepFunctions {
    if (!this._functions) {
      const onBefore = async () => {
        await this.loadGigya();
      };

      this._functions = new Proxy(functions, {
        get(target, prop, receiver) {
          const original = Reflect.get(target, prop, receiver);
          if (typeof original === "function") {
            return async (...args: Parameters<typeof original>) => {
              await onBefore();
              return original.apply(this, args);
            };
          }
          return original;
        }
      }) as unknown as FepFunctions;
    }

    return this._functions;
  }

  set onLogin(onLoginCallback: (account: ISapAccount) => void | Promise<void>) {
    if (this._events?.onLogin === onLoginCallback) {
      return;
    }

    this._events = this._events ?? {};

    this._events.onLogin = onLoginCallback;

    if (this._initialized) {
      this._subscribeToEvents();
    }
  }

  set onLogout(onLogoutCallback: () => void | Promise<void>) {
    if (this._events?.onLogout === onLogoutCallback) {
      return;
    }

    this._events = this._events ?? {};

    this._events.onLogout = onLogoutCallback;

    if (this._initialized) {
      this._subscribeToEvents();
    }
  }

  addEventListener(type: "login", listener: (account: ISapAccount) => void | Promise<void>): void;
  addEventListener(type: "logout", listener: () => void | Promise<void>): void;
  addEventListener(
    type: "login" | "logout",
    listener: ((account: ISapAccount) => void | Promise<void>) | (() => void | Promise<void>)
  ): void {
    switch (type) {
      case "login":
        this.onLogin = listener as (account: ISapAccount) => void | Promise<void>;
        break;
      case "logout":
        this.onLogout = listener as () => void | Promise<void>;
        break;
    }
  }

  setConfig(config: IConfig) {
    this._validateConfig(config);

    this._config = config;
    this._config.ssoEnabled = this._config.ssoEnabled ?? true;
    this._config.gaEnabled = this._config.gaEnabled ?? true;

    if (this._config.events?.onLogin) {
      this.onLogin = this._config.events.onLogin;
    }

    if (this._config.events?.onLogout) {
      this.onLogout = this._config.events.onLogout;
    }
  }

  async loadGigya(config: IConfig | undefined = this._config) {
    if (!config) {
      throw new FepError("Gigya configuration is undefined. Please provide a valid configuration object.");
    }

    if (this._initialized) {
      return;
    }

    this.setConfig(config);

    const gigyaScriptUrl = this._getGigyaUrl(config);

    injectFonts();

    await loadScript(gigyaScriptUrl);
    this._subscribeToEvents();

    this._patchGigya();

    this._initialized = true;
  }

  private _patchGigya() {
    Object.assign(window.gigya.thisScript.globalConf, gigyaFunctions);
  }

  private _subscribeToEvents() {
    if (this._events) {
      const events: IAddEventHandlersOptions = {};

      if (this._events.onLogin) {
        events.onLogin = async () => {
          try {
            const account = await functions.getAccountInfo();
            this.ga.login.loginSuccessEvent({ email: account.profile.email, type: "traditional" });
            await this._events!.onLogin!(account);
          } catch (error) {
            this.logger.error(error);
          }
        };
      }

      if (this._events.onLogout) {
        events.onLogout = async () => {
          try {
            await this._events!.onLogout!();
          } catch (error) {
            this.logger.error(error);
          }
        };
      }

      window.gigya.accounts.addEventHandlers(events);
    }
  }

  private _getSsoGigyaUrl() {
    const baseUrl = getSsoGigyaBaseUrl();
    return new URL(`${baseUrl}/js/gigya.js`);
  }

  private _getGigyaUrl(config: IConfig) {
    const gigyaScriptUrl = config.ssoEnabled ? this._getSsoGigyaUrl() : new URL("https://cdns.gigya.com/js/gigya.js");
    gigyaScriptUrl.searchParams.set("apiKey", config.apiKey);
    gigyaScriptUrl.searchParams.set("lang", config.lang);
    return gigyaScriptUrl;
  }

  private _validateConfig(config: IConfig) {
    const requiredFields: (keyof IConfig)[] = ["apiKey", "lang", "market"];
    const validPortals: (typeof config.portal)[] = ["medicalPortal", "mConnect", "customerLink"];

    for (const field of requiredFields) {
      if (!config[field]) {
        throw new FepError(`Configuration validation error: '${field}' is required but was not provided.`);
      }
    }

    if (config.portal && !validPortals.includes(config.portal)) {
      throw new FepError(`Configuration validation error: 'portal' must be one of ${validPortals.join(", ")}, but was '${config.portal}'.`);
    }
  }

  private _fetchAndParseConfig(): IConfig | undefined {
    const configTextObject = document.querySelector('script[src$="fep.js"]')?.textContent?.trim();

    if (configTextObject) {
      try {
        return parse(configTextObject);
      } catch (e) {
        throw new FepError("Failed to parse the FEP configuration script: Invalid JSON format. Please check the script content.");
      }
    }
  }

  private get _noStorePages() {
    return new RegExp([
      "my-profile"
    ].join("|"));
  }

  private _handleBFCacheRestore() {
    window.addEventListener("pageshow", event => {
      if (event.persisted && this._noStorePages.test(location.pathname)) {
        document.body.style.visibility = "hidden";
        return functions.isLoggedIn().then(isLoggedIn => {
          if (!isLoggedIn) {
            return window.location.reload();
          }
          document.body.style.visibility = "visible";
        });
      }
    });
  }

  isFepError(error: Error): boolean {
    return error instanceof FepError;
  }
}

/* eslint-disable-next-line @typescript-eslint/no-unsafe-declaration-merging */
export interface Fep extends FepLoggerMixin { }
applyMixins(Fep, [FepLoggerMixin]);

Object.defineProperty(window, "fep", {
  value: new Fep(),
  writable: false,
  enumerable: true,
  configurable: true
});
