import { CaptureEvent } from "./captureEvent";
import {
  ChangePasswordEvents,
  EditProfileEvents,
  ForgotPasswordEvents,
  LoginEvents,
  RegistrationEvents,
  ResetPasswordEvents,
  ValidationEvents
} from "./categories";

export class GoogleAnalytics extends CaptureEvent {
  screen = "Common";

  changePassword = new ChangePasswordEvents(this.enabled);
  editProfile = new EditProfileEvents(this.enabled);
  forgotPassword = new ForgotPasswordEvents(this.enabled);
  resetPassword = new ResetPasswordEvents(this.enabled);
  login = new LoginEvents(this.enabled);
  registration = new RegistrationEvents(this.enabled);
  validation = new ValidationEvents(this.enabled);

  backToLoginEvent(currentScreen: string) {
    this.captureEvent({
      id: "back_to_login_clicked",
      label: "Back to login screen clicked",
      screen: currentScreen,
      action: "navigation"
    });
  }
}
