import type { ICaptureEventOptions } from "@fep/interfaces";
import { FepError } from "../error";

export abstract class CaptureEvent {
  abstract screen: string;

  constructor(protected enabled: boolean) {
    if (this.enabled) {
      window.dataLayer = window.dataLayer || [];
    }
  }

  protected captureEvent(captureEvent: ICaptureEventOptions, screen?: string) {
    if (this.enabled) {
      window.dataLayer.push({
        screen: screen ?? this.screen,
        ...captureEvent,
        event: "sapCdcEvent",
        language: window.fep.lang,
        market: window.fep.market,
        portal: window.fep.portal
      });
    }
  }

  protected validateMetadata(metadata: Record<string, string | object>, fields: string[]) {
    fields.forEach(field => {
      if (!metadata[field]) {
        throw new FepError(`Property '${field}' must be specified in metadata.`);
      }
    });
  }
}
