import type { IShowScreenOptions } from "@fep/interfaces/iShowScreenOptions";
import { showScreenSet } from "@fep/gigyaFunction";

export function showLoginScreenWithUrlContext(options: IShowScreenOptions): void {
  if (window.fep.ssoEnabled) {
    const redirect = decodeURIComponent(options.redirect);
    const registrationPath = decodeURIComponent(options.registrationPath as string);
    window.gigya.sso.login({
      authFlow: "redirect",
      redirectURL: redirect,
      useChildContext: true,
      context: {
        registrationPath,
        redirect
      }
    });
  } else {
    void showScreenSet({
      screenSet: "Common-screenSet",
      containerID: options.containerID,
      screenSetKey: "login"
    });
  }
}
