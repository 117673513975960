/* eslint-disable @typescript-eslint/no-explicit-any */

export function applyMixins(derivedCtor: any, baseCtors: any[]) {
  baseCtors.forEach(baseCtor => {
    // Copy methods
    Object.getOwnPropertyNames(baseCtor.prototype).forEach(name => {
      if (name !== "constructor") {
        derivedCtor.prototype[name] = baseCtor.prototype[name];
      }
    });

    // Copy instance properties
    const instance = new baseCtor();
    Object.keys(instance).forEach(key => {
      derivedCtor.prototype[key] = instance[key];
    });
  });
}
